<template>
    <div class="app-calendar overflow-hidden border">
    <div class="row no-gutters">
      <!-- Sidebar -->
     <!-- <div
        class="col app-calendar-sidebar flex-grow-0 overflow-hidden d-flex flex-column"
        :class="{'show': isCalendarOverlaySidebarActive}"
      >
        <calendar-sidebar :is-event-handler-sidebar-active.sync="isEventHandlerSidebarActive" />
      </div>-->

      <!-- Calendar -->
      <div class="col position-relative">
        <div class="card shadow-none border-0 mb-0 rounded-0">
          <div class="card-body pb-0">
            <full-calendar
              ref="refCalendar"
              :options="calendarOptions"
              class="full-calendar"
            >
            <template v-slot:eventContent='arg'>
              <div :id="`popover-target-${arg.event.extendedProps.patient_id}`" v-if="arg.event.extendedProps.is_active == 0" class="rounded bg-danger text-white" style="padding: 0.5%;">
              <b>{{ arg.timeText }}</b>
              <i>{{ arg.event.title }}</i>
              </div>
              <div :id="`popover-target-${arg.event.extendedProps.patient_id}`" v-else-if="arg.event.extendedProps.is_active == 1" class="rounded bg-success text-white" style="padding: 0.5%;">
              <b>{{ arg.timeText }}</b>
              <i>{{ arg.event.title }}</i>
              </div>
              <div :id="`popover-target-${arg.event.extendedProps.patient_id}`" v-else-if="arg.event.extendedProps.is_active == 2" class="rounded bg-warning text-white" style="padding: 0.5%;">
              <b>{{ arg.timeText }}</b>
              <i>{{ arg.event.title }}</i>
              </div>

              <b-popover :variant="`${resolveStatusVariant(arg.event.extendedProps.is_active)}`" :target="`popover-target-${arg.event.extendedProps.patient_id}`" triggers="hover focus" placement="top">
                <template #title v-if="arg.event.extendedProps.is_active == 0">ยกเลิก</template>
                <template #title v-else-if="arg.event.extendedProps.is_active == 1">เสร็จสิ้น</template>
                <template #title v-else-if="arg.event.extendedProps.is_active == 2">รอดำเนินการ</template>
                <p class="text-dark">{{ arg.event.title }}</p>
                <p class="text-dark">แพทย์: {{ arg.event.extendedProps.doc_name }}</p>
                <p class="text-dark">คนไข้: {{ arg.event.extendedProps.patient_name }}</p>
                <p class="text-dark">รหัส: {{ arg.event.extendedProps.code_member }}</p>
                <p class="text-dark">วันที่นัด: {{ arg.event.extendedProps.date_meeting }}</p>
                <p class="text-dark">เวลา: {{ arg.event.extendedProps.time }}</p>

              </b-popover>
            </template>
            </full-calendar>
          </div>
        </div>
      </div>

      <!-- Sidebar Overlay -->
     
      <div
        class="body-content-overlay"
        :class="{'show': isCalendarOverlaySidebarActive}"
        @click="isCalendarOverlaySidebarActive = false"
      >
      </div>
       <!--<calendar-event-handler
        v-model="isEventHandlerSidebarActive"
        :event="event"
        :clear-event-data="clearEventData"
        @remove-event="removeEvent"
        @add-event="addEvent"
        @update-event="updateEvent"
      />-->
    </div>
  </div>
</template>
<script>
import {
    BPopover
} from 'bootstrap-vue'
import store from '@/store'
import { ref,onUnmounted } from '@vue/composition-api'
import FullCalendar from '@fullcalendar/vue'
import useCalendar from './useCalendar'
import appointmentStoreModule from '../appointmentStoreModule'
export default {
    components: {
        BPopover,
        FullCalendar, // make the <FullCalendar> tag available
   
    },
    setup() {
        const PATIENTS_STORE_MODULE_NAME = 'calendar';
        if (!store.hasModule(PATIENTS_STORE_MODULE_NAME)) store.registerModule(PATIENTS_STORE_MODULE_NAME, appointmentStoreModule)
        onUnmounted(() => {
            if (store.hasModule(PATIENTS_STORE_MODULE_NAME)) store.unregisterModule(PATIENTS_STORE_MODULE_NAME)
        });
        const {
            refCalendar,
            isCalendarOverlaySidebarActive,
            event,
            clearEventData,
            addEvent,
            updateEvent,
            removeEvent,
            fetchEvents,
            refetchEvents,
            calendarOptions,
            resolveStatusVariant,
            // ----- UI ----- //
            isEventHandlerSidebarActive,
        } = useCalendar();
        return {
            refCalendar,
            isCalendarOverlaySidebarActive,
            event,
            clearEventData,
            addEvent,
            updateEvent,
            removeEvent,
            fetchEvents,
            refetchEvents,
            calendarOptions,
            resolveStatusVariant,
            // ----- UI ----- //
            isEventHandlerSidebarActive,

        }
        
    },
}
</script>
<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";
</style>
